import {
  FitnessCenter,
  Email,
  Scale,
  CalendarMonth,
  Home,
  AssignmentTurnedIn,
  Close,
  EmojiEventsTwoTone,
} from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  SwipeableDrawer,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function LeftMenu({ drawerFunction, drawerState }) {
  LeftMenu.propTypes = {
    drawerFunction: PropTypes.func.isRequired,
    drawerState: PropTypes.bool.isRequired,
  };

  return (
    <SwipeableDrawer
      sx={{ position: 'absolute' }}
      open={drawerState}
      onClose={drawerFunction(false)}
      onOpen={drawerFunction(true)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={drawerFunction(false)}>
            <ListItemIcon>
              <Close />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/">
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/workouts">
            <ListItemIcon>
              <FitnessCenter />
            </ListItemIcon>
            <ListItemText primary="Workouts" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/workouts/complete">
            <ListItemIcon>
              <AssignmentTurnedIn />
            </ListItemIcon>
            <ListItemText primary="Completed Workouts" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/calendar">
            <ListItemIcon>
              <CalendarMonth />
            </ListItemIcon>
            <ListItemText primary="Work Out Schedule" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Scale />
            </ListItemIcon>
            <ListItemText primary="Weight Tracking" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/pbs">
            <ListItemIcon>
              <EmojiEventsTwoTone />
            </ListItemIcon>
            <ListItemText primary="My PBs" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/messages">
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <ListItemText primary="Messages" />
          </ListItemButton>
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
}

export default LeftMenu;
