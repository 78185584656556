import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';

import { requestForToken, onMessageListener } from '../firebase';
import LoginContext from './LoginContext';
import TraxJWT from './Auth/TraxJWT';

function Notifications() {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [fcmToken, setFcmToken] = useState('');
  const [isValidToken] = useContext(LoginContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    requestForToken()
      .then((token) => {
        setFcmToken(token);
      })
      .catch((err) => {
        throw Error(err);
      });
  }, []);

  useEffect(() => {
    // if fcmToken is same as the one in local storage, do nothing, else update it via post request
    const storedFCM = localStorage.getItem('fcmToken');

    if (isValidToken) {
      if (fcmToken !== storedFCM && fcmToken) {
        TraxJWT.post('/api/users/updatefcm', {
          fcmToken,
        })
          .then(() => {
            localStorage.setItem('fcmToken', fcmToken);
          })
          .catch((err) => {
            throw Error(err);
          });
      }
    }
  }, [fcmToken, isValidToken]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.data?.title,
        body: payload?.data?.body,
      });
    })
    .catch((err) => {
      throw Error(err);
    });

  useEffect(() => {
    if (notification.body) {
      enqueueSnackbar(notification.body, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
        // eslint-disable-next-line react/no-unstable-nested-components
        action: (key) => (
          <button
            type="button"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            Dismiss
          </button>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

export default Notifications;
