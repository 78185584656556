import axios from 'axios';

const TraxJWT = axios.create({});
TraxJWT.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  // send token in x-access-header
  // eslint-disable-next-line no-param-reassign
  config.headers['x-access-token'] = token;
  return config;
});

TraxJWT.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      // try to refresh token with http only cookie
      const refresh = await axios.post(
        '/api/users/refresh',
        {},
        { withCredentials: true }
      );
      if (refresh.status === 200) {
        localStorage.setItem('token', refresh.data.token);
        return TraxJWT.request(error.config);
      }
    }
    return Promise.reject(error);
  }
);
// comment

export default TraxJWT;
