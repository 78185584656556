import { createContext } from 'react';
import { isExpired, decodeToken } from 'react-jwt';

import axios from 'axios';

const LoginContext = createContext(null);

function isMyTokenExpired(token) {
  if (!token) {
    return true;
  }
  if (isExpired(token)) {
    // refresh token with http only cookie
    // console.log('token expired, trying to refresh');
    axios
      .post('/api/users/refresh', {}, { withCredentials: true })
      .then((res) => {
        // set new token
        console.log('token refreshed');
        localStorage.setItem('token', res.data.token);
        return false;
      })
      .catch(() => {
        // console.log('token refresh failed', err);
        // if refresh token fails, delete token
        localStorage.removeItem('token');
      });
    if (isExpired(token)) {
      return true;
    }
  }
  return false;
}

function getTokenData(token) {
  if (!token) {
    return null;
  }
  const decodedToken = decodeToken(token);
  return decodedToken;
}

export default LoginContext;
export { isMyTokenExpired };
export { getTokenData };
