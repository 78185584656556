import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginContext, { isMyTokenExpired } from './LoginContext';

function SignIn() {
  const location = useLocation();
  const [loginError, setLoginError] = useState(false);

  const [attemptEmail, setAttemptEmail] = useState('');
  const [attemptPassword, setAttemptPassword] = useState('');

  const [isValidToken, setIsValidToken] = useContext(LoginContext);
  const navigate = useNavigate();

  const handleSignIn = (e) => {
    // login with api call with axios
    setAttemptEmail(e.target.email.value || '');
    setAttemptPassword(e.target.password.value || '');
    e.preventDefault();
  };

  useEffect(() => {
    if (attemptEmail && attemptPassword) {
      axios
        .post('/api/users/signin', {
          email: attemptEmail,
          password: attemptPassword,
        })
        .then((res) => {
          localStorage.setItem('token', res.data.token);
          setIsValidToken(true);
          // if on signin page, redirect to home if on other page, redirect to that page
          if (location.pathname === '/signin') navigate('/');
          else navigate(0, { replace: true });
        })
        .catch(() => {
          setLoginError(true);
        });
    }
  }, [
    attemptEmail,
    attemptPassword,
    location.pathname,
    navigate,
    setIsValidToken,
  ]);

  useEffect(() => {
    // if loginError is true, set it to false after 5 seconds
    if (loginError) {
      setTimeout(() => {
        setLoginError(false);
      }, 2000);
    }
  }, [loginError]);

  useEffect(() => {
    // validate token to decide if we should show spinner or login
    if (localStorage.getItem('token')) {
      if (isMyTokenExpired(localStorage.getItem('token'))) {
        setIsValidToken(false);
      } else {
        setIsValidToken(true);
      }
    } else {
      setIsValidToken(false);
    }
  }, [setIsValidToken]);

  useEffect(() => {
    if (isValidToken) {
      navigate('/');
    }
  }, [isValidToken, navigate]);

  if (isValidToken) {
    return <div>You are already logged in</div>;
  }

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      flex={12}
      m={2}
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {loginError ? <Alert severity="error">Login Error</Alert> : null}
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Typography variant="body2" color="text.secondary" align="center">
          {"Don't have an account? "}
          <a href="/signup">Sign Up</a>
        </Typography>
      </Box>
    </Box>
  );
}

export default SignIn;
