import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
  palette: {
    primary: {
      main: '#27E1C1',
    },
    secondary: {
      main: '#F5F3C1',
    },
    text: {
      primary: '#27E1C1',
      secondary: '#27E1C1',
    },
    background: {
      default: '#000000',
      main: '#3b3b3b',
      paper: '#575757',
      paperrgb: 'rgba(87, 87, 87, 0.9)',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: {
      fontSize: '3rem',
      fontWeight: 'bold',
      letterSpacing: '-0.01562em',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 'bold',
      letterSpacing: '-0.00833em',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      letterSpacing: '0em',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      letterSpacing: '0.00735em',
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      letterSpacing: '0em',
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      letterSpacing: '0.0075em',
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 'normal',
      letterSpacing: '0.00938em',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      letterSpacing: '0.01071em',
      lineHeight: 1.5,
    },
  },
});

export const colors = {
  primary: '#27E1C1',
  secondary: '#F5F3C1',
  text: {
    primary: '#27E1C1',
    secondary: '#27E1C1',
  },
  background: {
    default: '#000000',
    main: '#3b3b3b',
    paper: '#575757',
    paperrgb: 'rgba(87, 87, 87, 0.9)',
  },
};
