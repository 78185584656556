import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginContext from './LoginContext';

function SignUp() {
  const [email, setEmail] = React.useState('');
  // eslint-disable-next-line camelcase
  const [first_name, setFirstName] = React.useState('');
  // eslint-disable-next-line camelcase
  const [last_name, setLastName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [alertText, setAlertText] = React.useState(null);
  const [isValidToken] = useContext(LoginContext);
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    axios
      .post('/api/users/signup', {
        // eslint-disable-next-line camelcase
        first_name,
        // eslint-disable-next-line camelcase
        last_name,
        email,
        password,
      })
      .then(() => {
        navigate('/signin');
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setAlertText('Email already in use');
        }
      });
  };

  useEffect(() => {
    if (password !== confirmPassword) {
      setAlertText('Passwords do not match');
    } else {
      setAlertText(null);
    }

    if (password.length < 8) {
      setAlertText('Password must be at least 8 characters');
    }

    if (
      password.length > 8 &&
      confirmPassword.length > 8 &&
      password === confirmPassword
    ) {
      setAlertText(null);
    }
  }, [password, confirmPassword, email]);

  useEffect(() => {
    // validate email
    if (email.length > 0) {
      if (email.includes('@') && email.includes('.')) {
        setAlertText(null);
      } else {
        setAlertText('Invalid email');
      }
    }
  }, [email]);

  if (isValidToken) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        flex={12}
        m={2}
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        You are already logged in
      </Box>
    );
  }

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      flex={12}
      m={2}
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {alertText && <Alert severity="info">{alertText} </Alert>}
      <Typography component="h1" variant="h5">
        Sign Up
      </Typography>
      <Box component="form" onSubmit={handleSignUp} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="first_name"
          label="First Name"
          name="first_name"
          autoComplete="first_name"
          autoFocus
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="last_name"
          label="Last Name"
          name="last_name"
          autoComplete="last_name"
          autoFocus
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmpassword"
          label="Confirm Password"
          type="password"
          id="confirmpassword"
          autoComplete="confirm-password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        {alertText && (
          <Typography align="center">Complete Form To Sign Up</Typography>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
          }}
          disabled={!!alertText}
        >
          Sign Up
        </Button>
      </Box>
    </Box>
  );
}

export default SignUp;
