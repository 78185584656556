import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import LeftMenu from './components/LeftMenu';
import Navbar from './components/Navbar';
import SignIn from './components/SignIn';
import SignOut from './components/SignOut';
import SignUp from './components/SignUp';
import Notifications from './components/Notifications';
import { themeOptions } from './assets/theme';
import LoginContext, { isMyTokenExpired } from './components/LoginContext';

const CalendarView = React.lazy(() => import('./components/Calendar'));
const LogWorkout = React.lazy(() => import('./components/LogWorkOut'));
const MainPage = React.lazy(() => import('./components/MainPage/MainPage'));
const MyPBs = React.lazy(() => import('./components/MyPBs'));
const AvailableWorkOuts = React.lazy(() =>
  import('./components/AvailableWorkOuts')
);
const CompletedWorkOuts = React.lazy(() =>
  import('./components/CompletedWorkOuts')
);
const Messages = React.lazy(() => import('./components/Messages'));
const CreateWorkout = React.lazy(() => import('./components/CreateWorkout'));
const MyAccount = React.lazy(() => import('./components/MyAccount'));

function App() {
  const [drawerState, setdrawerState] = useState(false);

  const [isValidToken, setIsValidToken] = useState(false);

  const drawerFunction = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setdrawerState(open);
  };
  useEffect(() => {
    // validate token to decide if we should show spinner or login
    if (localStorage.getItem('token')) {
      if (isMyTokenExpired(localStorage.getItem('token'))) {
        setIsValidToken(false);
      } else {
        setIsValidToken(true);
      }
    } else {
      setIsValidToken(false);
    }
  }, []);

  const tokenMemo = useMemo(() => {
    return [isValidToken, setIsValidToken];
  }, [isValidToken, setIsValidToken]);

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={themeOptions}>
          <CssBaseline />
          <SnackbarProvider>
            <LoginContext.Provider value={tokenMemo}>
              <Navbar drawerFunction={drawerFunction} />
              <LeftMenu
                drawerFunction={drawerFunction}
                drawerState={drawerState}
              />
              <Routes>
                <Route
                  path="/"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <MainPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/workouts"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <AvailableWorkOuts />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/workouts/complete"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <CompletedWorkOuts />
                    </React.Suspense>
                  }
                />
                <Route
                  path="workouts/create"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <CreateWorkout />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/workouts/log/:id"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <LogWorkout />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/workouts/log/:id/:existingid"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <LogWorkout />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/pbs"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <MyPBs />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <CalendarView />
                    </React.Suspense>
                  }
                />
                <Route path="signin" element={<SignIn />} />
                <Route path="signout" element={<SignOut />} />
                <Route path="signup" element={<SignUp />} />
                <Route
                  path="messages"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <Messages />
                    </React.Suspense>
                  }
                />
                <Route
                  path="myaccount"
                  element={
                    <React.Suspense fallback={<CircularProgress />}>
                      <MyAccount />
                    </React.Suspense>
                  }
                />
              </Routes>
              <Notifications />
            </LoginContext.Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
