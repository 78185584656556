import React, { useEffect, useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TraxLogo from '../assets/Trax.svg';
import LoginContext from './LoginContext';

function PrimarySearchAppBar({ drawerFunction }) {
  PrimarySearchAppBar.propTypes = {
    drawerFunction: PropTypes.func.isRequired,
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const isMenuOpen = Boolean(anchorEl);

  const [isValidToken] = useContext(LoginContext);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isValidToken) {
      return;
    }
    fetch('/api/messages/unreadcount', {
      headers: { 'x-access-token': localStorage.getItem('token') },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnreadMessageCount(data[0]?.count || 0);
      });
  }, [isValidToken]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component={Link} to="/myaccount" onClick={handleMenuClose}>
        My account
      </MenuItem>
      <MenuItem component={Link} to="/signout" onClick={handleMenuClose}>
        Sign Out
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={drawerFunction(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <img src={TraxLogo} alt="Trax Logo" height="40vh" />
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            <IconButton
              size="large"
              aria-label={`show ${parseInt(unreadMessageCount, 10)} new mails`}
              color="inherit"
              href="/messages"
            >
              <Badge
                badgeContent={parseInt(unreadMessageCount, 10)}
                color="error"
              >
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}

export default PrimarySearchAppBar;
