import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginContext from './LoginContext';
import TraxJWT from './Auth/TraxJWT';

function SignOut() {
  const navigate = useNavigate();

  const [fcmToken] = useState(localStorage.getItem('fcmToken'));
  const [, setIsValidToken] = useContext(LoginContext);

  useEffect(() => {
    TraxJWT.post('/api/users/deletefcm', {
      fcmToken,
    })
      .then(() => {
        localStorage.removeItem('fcmToken');
      })
      .catch((err) => {
        throw Error(err);
      });

    localStorage.removeItem('token');
    setIsValidToken(false);
    navigate('/');
  }, [navigate, fcmToken, setIsValidToken]);

  return <div>SignOut</div>;
}

export default SignOut;
