/* eslint-disable import/prefer-default-export */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDKA0COhPEF9xZP8NH2wV0JAJgkRKYDPZ0',
  authDomain: 'traxptnotifcations.firebaseapp.com',
  projectId: 'traxptnotifcations',
  storageBucket: 'traxptnotifcations.appspot.com',
  messagingSenderId: '549562796354',
  appId: '1:549562796354:web:fab56ca3ce92285104a0db',
  measurementId: 'G-SMGBF6KKJH',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    throw new Error('Not supported browser');
  } catch (err) {
    throw new Error(err);
  }
})();

export const requestForToken = async () => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      vapidKey:
        'BNnwKYJeKzY0v8Fw75BItXb5_yApyEGCuoXRLIg5wqwK96Nq5RwhxAGKl_SJL7Hi68eWoxIBxAe1mgKA7Ai_w-I',
    });
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      return currentToken;
    }
  } catch (err) {
    throw new Error('An error occurred while retrieving token. ', err);
  }
  return null;
};

export const onMessageListener = async () =>
  new Promise((resolve) => {
    const messagingResolve = messaging;
    onMessage(messagingResolve, (payload) => {
      // console.log('On message: ', messaging, payload);
      resolve(payload);
    });
  });
